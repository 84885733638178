var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ConnectionController } from '@web3modal/core';
import { customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';
let W3mConnectingHeader = class W3mConnectingHeader extends LitElement {
  constructor() {
    super();
    this.platformTabs = [];
    this.unsubscribe = [];
    this.platforms = [];
    this.onSelectPlatfrom = undefined;
    this.buffering = false;
    this.unsubscribe.push(ConnectionController.subscribeKey('buffering', val => this.buffering = val));
  }
  disconnectCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
  }
  render() {
    const tabs = this.generateTabs();
    return html`
      <wui-flex justifyContent="center" .padding=${['l', '0', '0', '0']}>
        <wui-tabs
          ?disabled=${this.buffering}
          .tabs=${tabs}
          .onTabChange=${this.onTabChange.bind(this)}
        ></wui-tabs>
      </wui-flex>
    `;
  }
  generateTabs() {
    const tabs = this.platforms.map(platform => {
      if (platform === 'browser') {
        return {
          label: 'Browser',
          icon: 'extension',
          platform: 'browser'
        };
      } else if (platform === 'mobile') {
        return {
          label: 'Mobile',
          icon: 'mobile',
          platform: 'mobile'
        };
      } else if (platform === 'qrcode') {
        return {
          label: 'Mobile',
          icon: 'mobile',
          platform: 'qrcode'
        };
      } else if (platform === 'web') {
        return {
          label: 'Webapp',
          icon: 'browser',
          platform: 'web'
        };
      } else if (platform === 'desktop') {
        return {
          label: 'Desktop',
          icon: 'desktop',
          platform: 'desktop'
        };
      }
      return {
        label: 'Browser',
        icon: 'extension',
        platform: 'unsupported'
      };
    });
    this.platformTabs = tabs.map(({
      platform
    }) => platform);
    return tabs;
  }
  onTabChange(index) {
    const tab = this.platformTabs[index];
    if (tab) {
      this.onSelectPlatfrom?.(tab);
    }
  }
};
__decorate([property({
  type: Array
})], W3mConnectingHeader.prototype, "platforms", void 0);
__decorate([property()], W3mConnectingHeader.prototype, "onSelectPlatfrom", void 0);
__decorate([state()], W3mConnectingHeader.prototype, "buffering", void 0);
W3mConnectingHeader = __decorate([customElement('w3m-connecting-header')], W3mConnectingHeader);
export { W3mConnectingHeader };
