<section class="main-section" *ngIf="!isUserRegistered">
  <div class="container-fluid ">
    <div class="section-main">
      @if (!isFounder) {
      <div class="row">
        <div class="col-lg-12">
          <div class="public-sale">
            <div class="row pl-0 pr-0 align-items-center row-gap-4">
              <div class="col-lg-6 col-md-12 pl-0 pr-0" *ngIf="startTime != undefined">
                <h1 *ngIf="startingTime < closingTime && checkSaleDate">Public Sale Count Down Ends In</h1>
                <h1 *ngIf="closingTime < currentTime && !checkSaleDate">Public Sale Count Down Ended.</h1>
                <h1 *ngIf="startingTime > closingTime">Private Presale Count Downstars Start In </h1>
                <p>Buy Now Before Price Increase in </p>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="row counting-view">
                  <div *ngIf="isLoading" class="loader"> </div>
                  <ng-container *ngFor="let schedule of schedules">
                    <div class="col-sm-2 col-6 counting d-flex justify-content-center">
                      <app-schedule-card [label]="schedule.label" [value]="schedule.value"></app-schedule-card>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
      <div class="row presale-main">
        <div class="col-lg-6 col-md-12 custom-mb custom-mb-md">
          @if (!isFounder) {
          <div class="presale Public">
            <h2 class="title">Public Sale</h2>
            <p class="pb-0">This Public Sale is currently in <span>Round 1</span></p>
            <div class="static-form">
              <div class="usdtdata">
                <div>
                  <h3>You pay</h3>
                  <div></div>
                  <input (keyup)="fromInput()" (keypress)="inputKeyPress($event, fromInputValue)"
                    [(ngModel)]="fromInputValue" type="text" name="from" id="from" placeholder="0.0">

                </div>
                <div>
                  <a class="usdtbtn"><img src="assets/images/usdt.svg" alt="USDT" width="30" height="30" class="img-fluid"> {{usdtTokenObject.symbol}}</a>
                  <h6>Balance : {{usdtTokenBalance}}</h6>
                </div>
              </div>
              <div class="usdtdata">
                <div>
                  <h3>You Receive</h3>
                  <input (keyup)="toInput()" (keypress)="inputKeyPress($event, toInputValue)"
                    [(ngModel)]="toInputValue" type="text" name="to" id="to" placeholder="0.0">
                </div>
                <div>
                  <a class="aocbtn">
                    <img src="assets/images/min-logo.svg" alt="sunwave" width="30" height="31" class="img-fluid"> {{sunwaveTokenObject.symbol}}</a>
                </div>
              </div>
            </div>

            <button *ngIf="isWrongNetwork" class="buytoken-disabled" (click)="switchNetwork()" type="button">
              <div *ngIf="isLoading" class="loader"> </div>
              <span *ngIf="!isLoading" class="buytoken w-100 d-block text-center"> SWITCH NETWORK </span>
              <span *ngIf="isLoading" class="loaderText"> Processing... Please wait! </span>
            </button>

            <button *ngIf="!isWrongNetwork" class="buytoken w-100 d-block text-center" [ngClass]="{'' : disableBuyNOW() || isFounder}"
              (click)="buyNow()" type="button" [disabled]="disableBuyNOW() || isFounder">
              <div *ngIf="isLoading" class="loader"> </div>
              <span *ngIf="!isFinalized">
                <span *ngIf="!checkSaleStartDate">
                  <span *ngIf="vestingUserInfo.monthsCompleted === this.vestingMonth"> VESTING DURATION ENDED
                  </span>
                  <span
                    *ngIf="!isLoading  && checkSaleDate && !(vestingUserInfo.monthsCompleted === this.vestingMonth)">
                    BUY TOKEN </span>
                  <span *ngIf="isLoading" class="loaderText"> Processing... Please wait!
                  </span>
                  <span *ngIf="!checkSaleDate">Sale Date Ended</span>
                </span>
                <span *ngIf="checkSaleStartDate">Sale yet not started</span>
              </span>
              <span *ngIf="isFinalized">Sale Finalized</span>
            </button>

          </div>
        } @else {
          <div class="presale Public">
            <h2 class="title">Founder Vesting Details</h2>
              <div class="usdt-details pt-3 pt-lg-4">
                <div class="detail">
                  <h5>Vesting Period</h5>
                  <h6>2 years</h6>
                </div>
                <div class="detail">
                  <h5>Vesting Frequency</h5>
                  <h6>Monthly</h6>
                </div>
                <div class="pt-1">
                  <h4>Vesting Schedule</h4>
                </div>
                <div class="detail">
                  <h5>Cliff period</h5>
                  <h6>6 months</h6>
                </div>
                <div class="detail">
                  <h5>Month 7-12</h5>
                  <h6>25% of allocation (208,333 SNW per month)</h6>
                </div>
                <div class="detail">
                  <h5>Year 2</h5>
                  <h6>75% of the total allocation (312,500 SNW per month)</h6>
                </div>

              </div>

          </div>
        }

        </div>

        <div class="col-lg-6 col-md-12">
          <div class="presale mb-40">
            <h3>Sun Wave Coin ({{sunwaveTokenObject.symbol}})</h3>
            <p>Phase Currently in <span>Public Sale</span> </p>
            <div class="mb-1">
              <h4>Contract address:</h4>
            </div>
            <div class="walletaddress">
              <ng-container *ngIf="sunwaveTokenObject.tokenAddress; else loading">
                <h5>
                  {{sunwaveTokenObject.tokenAddress.slice(0,11)}}.....{{sunwaveTokenObject.tokenAddress.slice(sunwaveTokenObject.tokenAddress.length
                  - 11)}}
                </h5>
              </ng-container>
              <span (click)="copyToClipboard(sunwaveTokenObject.tokenAddress)" class="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <g clip-path="url(#clip0_1056_307)">
                    <path d="M17.3067 9.99669C17.3067 9.28936 17.0257 8.611 16.5256 8.11084C16.0254 7.61068 15.3471 7.32969 14.6397 7.32969H5.97373C5.6235 7.32969 5.27669 7.39868 4.95312 7.5327C4.62954 7.66673 4.33553 7.86318 4.08788 8.11084C3.84023 8.35849 3.64377 8.6525 3.50974 8.97607C3.37572 9.29965 3.30673 9.64646 3.30673 9.99669V18.6627C3.30673 19.0129 3.37572 19.3597 3.50974 19.6833C3.64377 20.0069 3.84023 20.3009 4.08788 20.5485C4.33553 20.7962 4.62954 20.9926 4.95312 21.1267C5.27669 21.2607 5.6235 21.3297 5.97373 21.3297H14.6397C14.99 21.3297 15.3368 21.2607 15.6603 21.1267C15.9839 20.9926 16.2779 20.7962 16.5256 20.5485C16.7732 20.3009 16.9697 20.0069 17.1037 19.6833C17.2377 19.3597 17.3067 19.0129 17.3067 18.6627V9.99669Z" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.2947 17.0666C20.6014 16.8917 20.8565 16.6391 21.0342 16.3341C21.2119 16.0291 21.3059 15.6826 21.3067 15.3296V5.32957C21.3067 4.22957 20.4067 3.32957 19.3067 3.32957H9.30673C8.55673 3.32957 8.14873 3.71457 7.80673 4.32957" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1056_307">
                      <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24.3067 0.0870152)"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
            <div class="lines">
              <div class="dots"></div>
            </div>
            <div class="usdt-details">
              <div class="detail">
              <ng-container *ngIf="usdtTokenObject.symbol && tokenRate && sunwaveTokenObject.symbol; else loading">                
                <h5>1 {{usdtTokenObject.symbol}}</h5>
                <h6>= {{tokenRate}} {{sunwaveTokenObject.symbol}}</h6>
              </ng-container>
              </div>
              <div class="detail">
                <h5>Phase</h5>
                <h6>Round 1</h6>
              </div>
              @if (!isFounder) {
              <div class="detail">
                <h5>Total Contribution</h5>
                <ng-container *ngIf="(userInfo.usdtContributed && usdtTokenObject.symbol) || userInfo.usdtContributed == 0; else loading">
                  <h6>{{userInfo.usdtContributed}} {{usdtTokenObject.symbol}}</h6>
                </ng-container>
              </div>
            }
            </div>
          </div>
          <div class="presale">
            <div class="d-flex align-items-center justify-content-between flex-wrap gap-xl-4 gap-3">
              <div>
                <h3>Vesting Info</h3>
                @if (!isFounder) {
                  <p>Cliff Period: 
                    <span>
                      <!-- <ng-container *ngIf="transformedLockupPeriod || transformedLockupPeriod == '0' || transformedLockupPeriod == ''; else loading">                      
                        {{transformedLockupPeriod}}
                      </ng-container> -->
                      6 Months
                    </span>
                </p>
                }
              </div>
              <div>
                <button [ngClass]="{ 'disabled-button': isClaimLoading}" *ngIf="!isWrongNetwork"
                class="buytoken contribution-btn" (click)="claimVestedTokens()" type="button"
                  [disabled]="buttonState !== 'CLAIM YOUR TOKENS' || isClaimLoading">
                  <div *ngIf="isClaimLoading" class="loader"> </div>
                  <span *ngIf="!isClaimLoading && buttonState === 'CLAIM YOUR TOKENS'"> Claim your Tokens </span>
                  <span *ngIf="isClaimLoading" class="loaderText"> Processing... Please wait!
                  </span>
                  <span *ngIf="buttonState !== 'CLAIM YOUR TOKENS'">{{buttonState}}</span>
                </button>
              </div>
            </div>
                <div class="pt-32 mb-2">
                  <h4>Claim Info</h4>
                </div>
                <div class="mb-1">
                  <h4>Vesting address:</h4>
                </div>
                <div class="walletaddress">
                  <ng-container *ngIf="vestingAddress; else loading">
                    <h5>
                      {{vestingAddress.slice(0,11)}}.....{{vestingAddress.slice(vestingAddress.length
                      - 11)}}
                    </h5>
                  </ng-container>
                  <span (click)="copyToClipboard(vestingAddress)" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <g clip-path="url(#clip0_1056_307)">
                        <path d="M17.3067 9.99669C17.3067 9.28936 17.0257 8.611 16.5256 8.11084C16.0254 7.61068 15.3471 7.32969 14.6397 7.32969H5.97373C5.6235 7.32969 5.27669 7.39868 4.95312 7.5327C4.62954 7.66673 4.33553 7.86318 4.08788 8.11084C3.84023 8.35849 3.64377 8.6525 3.50974 8.97607C3.37572 9.29965 3.30673 9.64646 3.30673 9.99669V18.6627C3.30673 19.0129 3.37572 19.3597 3.50974 19.6833C3.64377 20.0069 3.84023 20.3009 4.08788 20.5485C4.33553 20.7962 4.62954 20.9926 4.95312 21.1267C5.27669 21.2607 5.6235 21.3297 5.97373 21.3297H14.6397C14.99 21.3297 15.3368 21.2607 15.6603 21.1267C15.9839 20.9926 16.2779 20.7962 16.5256 20.5485C16.7732 20.3009 16.9697 20.0069 17.1037 19.6833C17.2377 19.3597 17.3067 19.0129 17.3067 18.6627V9.99669Z" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20.2947 17.0666C20.6014 16.8917 20.8565 16.6391 21.0342 16.3341C21.2119 16.0291 21.3059 15.6826 21.3067 15.3296V5.32957C21.3067 4.22957 20.4067 3.32957 19.3067 3.32957H9.30673C8.55673 3.32957 8.14873 3.71457 7.80673 4.32957" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1056_307">
                          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24.3067 0.0870152)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
                <div class="lines">
                  <div class="dots"></div>
                </div>
                <div class="usdt-details">
                  <div class="detail">
                    <h5>Your Total Vesting Allocation</h5>
                    <ng-container *ngIf="(vestingUserInfo?.totalAmount && sunwaveTokenObject?.symbol) ||vestingUserInfo?.totalAmount == 0; else loading">
                       <h6>{{vestingUserInfo.totalAmount}} {{sunwaveTokenObject.symbol}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Total Claimed</h5>
                    <ng-container *ngIf="(vestingUserInfo?.claimedBalance && sunwaveTokenObject?.symbol) ||vestingUserInfo?.claimedBalance == 0; else loading">
                      <h6>{{vestingUserInfo.claimedBalance}} {{sunwaveTokenObject.symbol}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Current Interval Tokens Available for Claim</h5>
                    <ng-container *ngIf="sunwaveTokenObject?.symbol; else loading">
                      <h6>{{vestingUserInfo.currentIntervalAmount}} {{sunwaveTokenObject.symbol}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Total Remaining Tokens for Claim *(Includes all Interval)</h5>
                    <ng-container *ngIf="sunwaveTokenObject?.symbol; else loading">
                      <h6>{{vestingUserInfo.availableToClaim}} {{sunwaveTokenObject.symbol}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Number of Months Claimed</h5>
                    <ng-container *ngIf="vestingUserInfo?.monthsCompleted || vestingUserInfo?.monthsCompleted == 0; else loading">
                      <h6>{{vestingUserInfo.monthsCompleted}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Total Vesting Months</h5>
                    <ng-container *ngIf="vestingMonth!=0; else loading">
                      <h6>{{vestingMonth}}</h6>
                    </ng-container>
                  </div>
                  <div class="detail">
                    <h5>Claim Date & Time</h5>
                    <h6 *ngIf="shouldShowNextClaimDate()">{{vestingUserInfo.nextClaimDatetime | date:'full'}}</h6>
                    <h6 *ngIf="getDefaultDate()">DD/MM/YYYY</h6>
                  </div>                 
                </div>
                <ng-template #loading>
                  <small class="text-secondary">loading...</small>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
<app-signup *ngIf="isUserRegistered" [currentWalletAddress]="walletAddress"></app-signup>
<!-- 450 lines -->
