import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KYC_STATUS } from 'src/app/interfaces/common..interface';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RegisterSchema } from '../auth.schema';
import { ApiResponse, UserRegisterStatus } from 'src/app/services/types/api-schema.types';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @Input() currentWalletAddress:string = ''
  public signUpForm: FormGroup;
  public account: any;
  public countryList: any;
  public isRegister: boolean = false;
  public kycForm: FormGroup;
  public showKYCModal: boolean = false;
  public frontImage: string;
  public backImage: string;
  public user: any;
  public frontImageExtension: any;
  public backImageExtension: any;
  walletAddress: string;


  constructor(
    private formbuilder: FormBuilder,
    private authService: AuthService,
    private routerNavigator: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit() {
    // this.loadData();
    this.initSignupForm();
    const userWalletInfo = window.localStorage.getItem('wagmi.store');
    this.account = JSON.parse(userWalletInfo as any);
    this.authService.walletAddress$.subscribe(address => {
      this.walletAddress = address;
    });
    this.signUpForm?.patchValue({ wallet_address: this.walletAddress });
  }

  /**
   * Getter for KYC status enum.
   */
  get kycStatus(): typeof KYC_STATUS{
    return KYC_STATUS
  }
  /**
   * Initializes the sign-up form.
   * 
   */
  private initSignupForm(){
    this.signUpForm = this.formbuilder.group({
      name: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('[a-zA-Z][a-zA-Z ]+')]],
      email: ['', [Validators.required, Validators.pattern(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )]],
      wallet_address: ['', Validators.required],
      agreements: ['']
    });
  }

  /**
   * Handles user registration.
   * 
   * Sets register flag to true, checks if sign up form is valid, 
   * creates register data object, calls register API, 
   * and handles successful registration or error responses.
   */
  protected register() {
    this.isRegister = true;
    if (this.signUpForm.valid) {
      const registerData: RegisterSchema = {
        name: this.signUpForm.value.name,
        email: this.signUpForm.value.email,
        wallet_address: this.walletAddress,
        agreements: this.signUpForm.value.agreements,
      };
      delete registerData.agreements;
      this.authService.register(registerData).subscribe({
        next: (response) => {
          this.user = response.data.user;
          this.localStorageService.setToken(response.data.session.session_token);
          this.isRegister = false;
          this.toastr.success('User Registered Successfully');
          window.location.reload();
        },
        error: (error) => {
          this.isRegister = false
          this.toastr.error(error.error.message)}
      })
    }
  }

  /**
   * Navigates route strategy
   */
  private navigateRouteStrategy() {
    const queryParams = { 'refresh': new Date().getTime() };
    this.routerNavigator.navigate(['/'], { queryParams: queryParams, relativeTo: this.route });
  }

}
