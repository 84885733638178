<section class="main-dashboard">
    <header>
        <nav class="navbar navbar-expand-lg" id="navbar_top">
            <div class="container-fluid cursor-pointer">
              <a class="navbar-brand cursor-pointer" >
                <img src="assets/images/logo.svg " alt="darkmode" class="img-fluid " width="180">
              </a>
              <div>
                <form class="right-side">
                  <w3m-button balance='hide' />
                </form>
              </div>
            </div>
        </nav>
    </header>
</section>
