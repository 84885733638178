import { ChainNotConfiguredForConnectorError, ConnectorNotFoundError } from "./chunk-UGBGYVBH.js";
import { normalizeChainId } from "./chunk-OQILYQDO.js";
import { Connector, __privateAdd, __privateGet, __privateSet } from "./chunk-W65LBPLT.js";

// src/injected.ts
import { ResourceUnavailableRpcError, SwitchChainError, UserRejectedRequestError, createWalletClient, custom, getAddress, numberToHex } from "viem";

// src/utils/getInjectedName.ts
function getInjectedName(ethereum) {
  if (!ethereum) return "Injected";
  const getName = provider => {
    if (provider.isApexWallet) return "Apex Wallet";
    if (provider.isAvalanche) return "Core Wallet";
    if (provider.isBackpack) return "Backpack";
    if (provider.isBifrost) return "Bifrost Wallet";
    if (provider.isBitKeep) return "BitKeep";
    if (provider.isBitski) return "Bitski";
    if (provider.isBlockWallet) return "BlockWallet";
    if (provider.isBraveWallet) return "Brave Wallet";
    if (provider.isCoin98) return "Coin98 Wallet";
    if (provider.isCoinbaseWallet) return "Coinbase Wallet";
    if (provider.isDawn) return "Dawn Wallet";
    if (provider.isDefiant) return "Defiant";
    if (provider.isDesig) return "Desig Wallet";
    if (provider.isEnkrypt) return "Enkrypt";
    if (provider.isExodus) return "Exodus";
    if (provider.isFordefi) return "Fordefi";
    if (provider.isFrame) return "Frame";
    if (provider.isFrontier) return "Frontier Wallet";
    if (provider.isGamestop) return "GameStop Wallet";
    if (provider.isHaqqWallet) return "HAQQ Wallet";
    if (provider.isHyperPay) return "HyperPay Wallet";
    if (provider.isImToken) return "ImToken";
    if (provider.isHaloWallet) return "Halo Wallet";
    if (provider.isKuCoinWallet) return "KuCoin Wallet";
    if (provider.isMathWallet) return "MathWallet";
    if (provider.isNovaWallet) return "Nova Wallet";
    if (provider.isOkxWallet || provider.isOKExWallet) return "OKX Wallet";
    if (provider.isOktoWallet) return "Okto Wallet";
    if (provider.isOneInchIOSWallet || provider.isOneInchAndroidWallet) return "1inch Wallet";
    if (provider.isOneKey) return "OneKey Wallet";
    if (provider.isOpera) return "Opera";
    if (provider.isPhantom) return "Phantom";
    if (provider.isPortal) return "Ripio Portal";
    if (provider.isRabby) return "Rabby Wallet";
    if (provider.isRainbow) return "Rainbow";
    if (provider.isSafePal) return "SafePal Wallet";
    if (provider.isStatus) return "Status";
    if (provider.isSubWallet) return "SubWallet";
    if (provider.isTalisman) return "Talisman";
    if (provider.isTally) return "Taho";
    if (provider.isTokenPocket) return "TokenPocket";
    if (provider.isTokenary) return "Tokenary";
    if (provider.isTrust || provider.isTrustWallet) return "Trust Wallet";
    if (provider.isTTWallet) return "TTWallet";
    if (provider.isXDEFI) return "XDEFI Wallet";
    if (provider.isZeal) return "Zeal";
    if (provider.isZerion) return "Zerion";
    if (provider.isMetaMask) return "MetaMask";
  };
  if (ethereum.providers?.length) {
    const nameSet = /* @__PURE__ */new Set();
    let unknownCount = 1;
    for (const provider of ethereum.providers) {
      let name = getName(provider);
      if (!name) {
        name = `Unknown Wallet #${unknownCount}`;
        unknownCount += 1;
      }
      nameSet.add(name);
    }
    const names = [...nameSet];
    if (names.length) return names;
    return names[0] ?? "Injected";
  }
  return getName(ethereum) ?? "Injected";
}

// src/injected.ts
var _provider;
var InjectedConnector = class extends Connector {
  constructor({
    chains,
    options: options_
  } = {}) {
    const options = {
      shimDisconnect: true,
      getProvider() {
        if (typeof window === "undefined") return;
        const ethereum = window.ethereum;
        if (ethereum?.providers && ethereum.providers.length > 0) return ethereum.providers[0];
        return ethereum;
      },
      ...options_
    };
    super({
      chains,
      options
    });
    this.id = "injected";
    __privateAdd(this, _provider, void 0);
    this.shimDisconnectKey = `${this.id}.shimDisconnect`;
    this.onAccountsChanged = accounts => {
      if (accounts.length === 0) this.emit("disconnect");else this.emit("change", {
        account: getAddress(accounts[0])
      });
    };
    this.onChainChanged = chainId => {
      const id = normalizeChainId(chainId);
      const unsupported = this.isChainUnsupported(id);
      this.emit("change", {
        chain: {
          id,
          unsupported
        }
      });
    };
    this.onDisconnect = async error => {
      if (error.code === 1013) {
        const provider = await this.getProvider();
        if (provider) {
          const isAuthorized = await this.getAccount();
          if (isAuthorized) return;
        }
      }
      this.emit("disconnect");
      if (this.options.shimDisconnect) this.storage?.removeItem(this.shimDisconnectKey);
    };
    const provider = options.getProvider();
    if (typeof options.name === "string") this.name = options.name;else if (provider) {
      const detectedName = getInjectedName(provider);
      if (options.name) this.name = options.name(detectedName);else {
        if (typeof detectedName === "string") this.name = detectedName;else this.name = detectedName[0];
      }
    } else this.name = "Injected";
    this.ready = !!provider;
  }
  async connect({
    chainId
  } = {}) {
    try {
      const provider = await this.getProvider();
      if (!provider) throw new ConnectorNotFoundError();
      if (provider.on) {
        provider.on("accountsChanged", this.onAccountsChanged);
        provider.on("chainChanged", this.onChainChanged);
        provider.on("disconnect", this.onDisconnect);
      }
      this.emit("message", {
        type: "connecting"
      });
      const accounts = await provider.request({
        method: "eth_requestAccounts"
      });
      const account = getAddress(accounts[0]);
      let id = await this.getChainId();
      let unsupported = this.isChainUnsupported(id);
      if (chainId && id !== chainId) {
        const chain = await this.switchChain(chainId);
        id = chain.id;
        unsupported = this.isChainUnsupported(id);
      }
      if (this.options.shimDisconnect) this.storage?.setItem(this.shimDisconnectKey, true);
      return {
        account,
        chain: {
          id,
          unsupported
        }
      };
    } catch (error) {
      if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error);
      if (error.code === -32002) throw new ResourceUnavailableRpcError(error);
      throw error;
    }
  }
  async disconnect() {
    const provider = await this.getProvider();
    if (!provider?.removeListener) return;
    provider.removeListener("accountsChanged", this.onAccountsChanged);
    provider.removeListener("chainChanged", this.onChainChanged);
    provider.removeListener("disconnect", this.onDisconnect);
    if (this.options.shimDisconnect) this.storage?.removeItem(this.shimDisconnectKey);
  }
  async getAccount() {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    const accounts = await provider.request({
      method: "eth_accounts"
    });
    return getAddress(accounts[0]);
  }
  async getChainId() {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    return provider.request({
      method: "eth_chainId"
    }).then(normalizeChainId);
  }
  async getProvider() {
    const provider = this.options.getProvider();
    if (provider) __privateSet(this, _provider, provider);
    return __privateGet(this, _provider);
  }
  async getWalletClient({
    chainId
  } = {}) {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()]);
    const chain = this.chains.find(x => x.id === chainId);
    if (!provider) throw new Error("provider is required.");
    return createWalletClient({
      account,
      chain,
      transport: custom(provider)
    });
  }
  async isAuthorized() {
    try {
      if (this.options.shimDisconnect && !this.storage?.getItem(this.shimDisconnectKey)) return false;
      const provider = await this.getProvider();
      if (!provider) throw new ConnectorNotFoundError();
      const account = await this.getAccount();
      return !!account;
    } catch {
      return false;
    }
  }
  async switchChain(chainId) {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    const id = numberToHex(chainId);
    try {
      await Promise.all([provider.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: id
        }]
      }), new Promise(res => this.on("change", ({
        chain
      }) => {
        if (chain?.id === chainId) res();
      }))]);
      return this.chains.find(x => x.id === chainId) ?? {
        id: chainId,
        name: `Chain ${id}`,
        network: `${id}`,
        nativeCurrency: {
          name: "Ether",
          decimals: 18,
          symbol: "ETH"
        },
        rpcUrls: {
          default: {
            http: [""]
          },
          public: {
            http: [""]
          }
        }
      };
    } catch (error) {
      const chain = this.chains.find(x => x.id === chainId);
      if (!chain) throw new ChainNotConfiguredForConnectorError({
        chainId,
        connectorId: this.id
      });
      if (error.code === 4902 || error?.data?.originalError?.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [{
              chainId: id,
              chainName: chain.name,
              nativeCurrency: chain.nativeCurrency,
              rpcUrls: [chain.rpcUrls.public?.http[0] ?? ""],
              blockExplorerUrls: this.getBlockExplorerUrls(chain)
            }]
          });
          const currentChainId = await this.getChainId();
          if (currentChainId !== chainId) throw new UserRejectedRequestError(new Error("User rejected switch after adding network."));
          return chain;
        } catch (error2) {
          throw new UserRejectedRequestError(error2);
        }
      }
      if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error);
      throw new SwitchChainError(error);
    }
  }
  async watchAsset({
    address,
    decimals = 18,
    image,
    symbol
  }) {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    return provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address,
          decimals,
          image,
          symbol
        }
      }
    });
  }
  isUserRejectedRequestError(error) {
    return error.code === 4001;
  }
};
_provider = new WeakMap();
export { InjectedConnector };