
import { switchNetwork, watchAccount, watchNetwork } from '@wagmi/core';
import { Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { environment } from 'src/environments/environment';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { firstValueFrom, interval, Observable, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import * as wagmiConfig from './wagmi.config'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private readonly authService: AuthService, private readonly localStorageService: LocalStorageService) {}
  connectorInfo: any;
  isLoading = false;

  ngOnInit() {
    this.wagmiConfiguration();
  }
  /**
   * Wagmis configuration
   */
  public wagmiConfiguration() {
    const projectId = environment.WALLET_CONNECT_PROJECT_ID;
    const modal = createWeb3Modal({
      wagmiConfig: defaultWagmiConfig(wagmiConfig.config.metaData),
      projectId,
      themeMode: 'light',
      enableAnalytics: wagmiConfig.config.enableAnalytics, 
      themeVariables: wagmiConfig.config.themeVariables
    })

    watchAccount(async (account) => {
      // Create an observable that emits at a 10ms interval until the wagmi store is updated
      const wagmiStore$ = interval(10).pipe(
        takeUntil(
          new Observable(observer => {
            // Check if the wagmi store has the user's account address
            const wagmiStore = JSON.parse(localStorage.getItem('wagmi.store') || '{}');
            if (wagmiStore.state?.data?.account === account.address) {
              observer.next();
              observer.complete();
            }            
          })
        )
      );
      // Wait for the first value from the observable, or null if it completes without emitting
      await firstValueFrom(wagmiStore$, { defaultValue: null });
      // Get the wagmi store from local storage and parse it as JSON
      const wagmiAccount = JSON.parse(localStorage.getItem('wagmi.store') || '{}');
      // Update the connector info with the user's account address
      (this.connectorInfo = wagmiAccount?.state?.data?.account) || this.localStorageService.removeToken();
      // // Update the wallet address in the dashboard service
      this.authService.updateWalletAddress(this.connectorInfo);
    });

    watchNetwork(async (network) => {
      if (network.chain?.unsupported) {
        setTimeout(() => {
        this.switchNetwork();
        }, 2000);
      }
    });
  }

  /**
   * Switchs network
   */
  public async switchNetwork() {
    this.isLoading = true;
    switchNetwork({
      chainId: environment.CHAIN_ID
    }).then(() => {
      this.isLoading = false;
    });
  }

}

//123 line
