import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './module/home/home.component';
import { AppComponent } from './app.component';
import { SignupComponent } from './auth/signup/signup.component';
import { TermsOfServiceComponent } from './shared/component/terms-of-service/terms-of-service.component';

const routes: Routes = [
  { path:'', component:HomeComponent, pathMatch:'full'},
  { path:'terms-of-service', component:TermsOfServiceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
