import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { prepareSendTransaction, sendTransaction, waitForTransaction } from '@wagmi/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(environment.PROVIDER);
const abi = require('src/contracts/abi/contract.json');
const contract = new web3.eth.Contract(abi, environment.CONTRACT_ADDRESS);
const abiToken = require('src/contracts/abi/tokenSale.json');
const vestingAbi = require('src/contracts/abi/vestingAbi.json');

const base_url = environment.API_BASE_URL;


@Injectable({
  providedIn: 'root'
})
export class ContractService {
  public vestingMonths: number = 0;

  constructor(
    private _http: HttpClient
  ) {
    this.init();
  }

  private async init() {
    this.vestingMonths = await this.getVestingMonths();

  }
  /**
   * Gets name
   * @param {string} tokenAddress
   * @returns
   */
  async getName(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const name = await contractToken.methods.name().call();
    return name;
  }

  /**
   * Gets token info
   * @param tokenAddress
   * @returns
   */
  async getTokenInfo(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const name = await contractToken.methods.name().call();
    const symbol = await contractToken.methods.symbol().call();
    const decimal = await contractToken.methods.decimals().call();
    return { tokenAddress, name, symbol, decimal };
  }

  /**
   * Gets allowance
   * @param spender
   * @param tokenAddress
   * @returns
   */
  async getAllowance(spender: string, tokenAddress: string) {
    const tokenContract = new web3.eth.Contract(abiToken, tokenAddress);
    let approvedAmount = await tokenContract.methods.allowance(spender, environment.CONTRACT_ADDRESS).call();
    let usdtBalance = await tokenContract.methods.balanceOf(spender).call();
    return { approvedAmount, usdtBalance }
  }

  /**
   * Approves abi
   * @param spender
   * @param amount
   * @param tokenAddress
   * @returns
   */
  async approveAbi(spender: any, amount: any, tokenAddress: string) {
    const tokenContract = new web3.eth.Contract(abiToken, tokenAddress);
    const decimal = await tokenContract.methods.decimals().call();
    let params = [spender, String(amount * 10 ** decimal)]
    return await tokenContract.methods.approve(...params).encodeABI();
  }

  /**
   * Gets user balances
   * @param walletAddress
   * @returns
   */
  async getUserBalances(walletAddress: string) {
    return await contract.methods.users(walletAddress).call();
  }

  /**
   * Gets reward token balance for sale
   * @returns
   */
  async getRewardTokenBalanceForSale() {
    const tokenAddress = await this.getTokenAddress();
    const tokenContract = new web3.eth.Contract(abiToken, tokenAddress);
    return tokenContract.methods.balanceOf(environment.CONTRACT_ADDRESS).call();
  }

  /**
   * Gets initial lockup period
   * @returns
   */
  async getInitialLockupPeriod() {
    return await contract.methods.initialLockInPeriodInSeconds().call();
  }

  /**
   * Gets vesting months
   * @returns
   */
  async getVestingMonths() {
    return await contract.methods.vestingMonths().call();
  }

  /**
   * Gets vesting address
   * @returns
   */
  async getVestingAddress() {
    return await contract.methods.vestingAddress().call();
  }

  /**
   * Gets vesting contract instance
   * @returns
   */
  async getVestingContractInstance() {
    const vestingAddress = await this.getVestingAddress();
    return new web3.eth.Contract(vestingAbi, vestingAddress);
  }

  /**
   * Claims vested tokens
   * @param round
   * @returns
   */
  async claimVestedTokens(round: number) {
    const vestingContractInstance = await this.getVestingContractInstance();
    return await vestingContractInstance.methods.claimVestedTokens(round).encodeABI();
  }

  async isFounder(receipt: string) {
    const vestingContractInstance = await this.getVestingContractInstance();
    // const founderVestingMonths = await vestingContractInstance.methods.founderVestingDuration().call();
    const isFounder = await vestingContractInstance.methods.isFounder(receipt).call();
    return isFounder;
  }

  /**
   * Gets user vesting datas
   * @param receipt
   * @param round
   * @returns
   */
  async getUserVestingDatas(receipt: string, round: number) {
    const vestingContractInstance = await this.getVestingContractInstance();
    const getFounder = await this.isFounder(receipt);
    const isFounder = getFounder.isFounder
    if (getFounder.isFounder) {
      const founderVestingMonths = 24;
      this.vestingMonths = founderVestingMonths;
    }
    const claimedTokens = await vestingContractInstance.methods.getTotalGrantClaimed(receipt, round).call();
    const totalAmount = await vestingContractInstance.methods.getGrantAmount(receipt, round).call();
    const userVestingStartTime = await vestingContractInstance.methods.getGrantStartTime(receipt, round).call();
    const availableToClaim = await vestingContractInstance.methods.remainingToken(receipt, round).call();
    const nextClaimDate = await vestingContractInstance.methods.nextClaimDate(receipt, round).call();
    let vestingCurrentIntervalDatas;
    if (claimedTokens[0] != this.vestingMonths && totalAmount != 0) {
      vestingCurrentIntervalDatas = await vestingContractInstance.methods.calculateGrantClaim(receipt, round).call();
    } else {
      vestingCurrentIntervalDatas = [0, 0];
    }
    return { claimedTokens, availableToClaim, nextClaimDate, totalAmount, vestingCurrentIntervalDatas, isFounder, userVestingStartTime };
  }

  /**
   * Gets interval time
   * @returns
   */
  async getIntervalTime() {
    const vestingContractInstance = await this.getVestingContractInstance();
    return await vestingContractInstance.methods.intervalTime().call();
  }

  /**
   * Gets token address
   * @returns
   */
  async getTokenAddress() {
    return await contract.methods.rewardToken().call();
  }

  /**
   * Gets usdttoken address
   * @returns
   */
  async getUSDTTokenAddress() {
    return await contract.methods.usdtToken().call();
  }
  /**
   * Buys token
   * @param {string} address
   * @param {number} usdtAmount
   * @returns
   */
  public async buyToken(address: string, usdtAmount: number) {
    let params = [address, usdtAmount]
    console.log('params', params);
    return await contract.methods.buyToken(...params).encodeABI();
  }

  /**
   * Claims bonus
   * @returns
   */
  public async claimBonus() {
    return await contract.methods.claimBonus().encodeABI();
  }


  /**
   * Gets symbol
   * @param {string} tokenAddress
   * @returns
   */
  async getSymbol(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const getSymbol = await contractToken.methods.symbol().call();
    return getSymbol;
  }
  /**
   * Gets decimal
   * @param tokenAddress
   * @returns
   */
  async getDecimal(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const getDecimal = await contractToken.methods.decimals().call();
    return getDecimal;
  }
  /**
   * Gets balance of
   * @param {string} address
   * @param {string} tokenAddress
   * @returns
   */
  async getBalanceOf(address: string, tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const balanceOf = await contractToken.methods.balanceOf(address).call();
    return balanceOf;
  }
  /**
   * Gets rate
   * @returns
   */
  async getRate() {
    const rate = await contract.methods.rate().call();
    return rate;
  }

  /**
   * Gets closing time
   * @returns
   */
  async getClosingTime() {
    const closingTime = await contract.methods.closingTime().call();
    return closingTime;
  }
  /**
   * Gets starting time
   * @returns
   */
  async getStartingTime() {
    const startingTime = await contract.methods.openingTime().call();
    return startingTime;
  }
  /**
   * Gets contract address
   * @returns
   */
  public getContractAddress() {
    const ContractAddress = environment.CONTRACT_ADDRESS;
    return ContractAddress;
  }

  /**
   * Totals supply
   * @param {string} tokenAddress
   * @returns
   */
  async totalSupply(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    const totalSupply = await contractToken.methods.totalSupply().call();
    return totalSupply;
  }

  /**
   * Determines whether finalized is
   * @returns
   */
  async isFinalized() {
    return await contract.methods.isFinalized().call();
  }
  /**
   * Determines whether wallets partner is
   * @param {tokenAddress} tokenAddress
   * @returns
   */
  public async isWalletsPartner(tokenAddress: string) {
    const contractToken = new web3.eth.Contract(abiToken, tokenAddress);
    return await contractToken.methods['isPartnerRegistered']().call();

  }


  /**
   * Creates order
   * @param {object} data
   * @returns
   */
  public createOrder(data: object) {
    return this._http.post(`${base_url}/user/order/create`, data)
  }


  /**
   * Gets wallet address
   * @param name
   * @returns
   */
  public getWalletAddress(name: string) {
    return this._http.get(`${base_url}/user/partner/details?name=${name}`)
  }

  /**
 * Sends transaction
 * @param tokenAbi
 * @param toAddress
 * @param [value]
 */
  async sendTransaction(tokenAbi: any, toAddress: any, value?: any) {
    const config = await prepareSendTransaction({
      to: toAddress,
      data: tokenAbi,
      value: value
    });
    return new Promise(async (resolve, reject) => {
      await sendTransaction(config).then(async (receipt: any) => {
        await waitForTransaction({ hash: receipt.hash }).then((response: any) => {
          resolve({ status: true, data: response });
        }).catch((error: any) => {
          reject({ status: false, data: error });
        });
        resolve({ status: true, data: receipt });
      }).catch((error: any) => {
        reject({ status: false, data: error });
      });
    })
  }

}
