import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse, UserRegisterStatus } from './types/api-schema.types';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private walletAddressSubject = new BehaviorSubject<string>('');
  walletAddress$ = this.walletAddressSubject.asObservable();

  constructor(private http: HttpClient) { }

  /**
   * Checks user registration status
   * @param address
   * @returns user registration status
   */
  public checkUserRegistrationStatus(walletAddress: string): Observable<ApiResponse<UserRegisterStatus>> {
    return this.http.get<ApiResponse<UserRegisterStatus>>(`${environment.API_BASE_URL}/user/check-registration-status?address=${walletAddress}`)
      .pipe(
        switchMap((response: any) => of(response)),
        catchError((error: any) => {
          return throwError(() => error);
        }),
      );
  }

  /**
   * Registers auth service
   * @returns register
   */
  public register(params: any): Observable<ApiResponse<UserRegisterStatus>> {
    return this.http.post<ApiResponse<UserRegisterStatus>>(`${environment.API_BASE_URL}/user/signup`, params)
      .pipe(
        switchMap((response: any) => of(response)),
        catchError((error: any) => {
          return throwError(() => error);
        }),
      );
  }

  public imageUpload(params: any): Observable<any> {
    return this.http.post<any[]>(`${environment.API_BASE_URL}/upload`, params)
      .pipe(
        switchMap((response: any) => of(response)),
        catchError((error: any) => {
          return throwError(() => error);
        }),
      );
  }

  /**
   * Reverifys kyc
   * @param email
   * @returns kyc
   */
  public reverifyKyc(email: string): Observable<any> {
    return this.http.get<any[]>(`${environment.API_BASE_URL}/user/kyc-reverification?email=${email}`)
      .pipe(
        switchMap((response: any) => of(response)),
        catchError((error: any) => {
          return throwError(() => error);
        }),
      );
  }

  /**
   * Legals disclaimer agree
   * @param userId
   * @returns disclaimer agree
   */
  public legalDisclaimerAgree(address: string): Observable<any> {
    return this.http.patch<any[]>(`${environment.API_BASE_URL}/user/disclaimer?wallet_address=${address}`, {})
      .pipe(
        switchMap((response: any) => of(response)),
        catchError((error: any) => {
          return throwError(() => error);
        }),
      );
  }

  /**
   * Updates wallet address
   * @param address 
   */
  updateWalletAddress(address: string) {
    this.walletAddressSubject.next(address);
  }

}
