export const ConstantsUtil = {
  WALLET_CONNECT_CONNECTOR_ID: 'walletConnect',
  INJECTED_CONNECTOR_ID: 'injected',
  COINBASE_CONNECTOR_ID: 'coinbaseWallet',
  SAFE_CONNECTOR_ID: 'safe',
  LEDGER_CONNECTOR_ID: 'ledger',
  EIP6963_CONNECTOR_ID: 'eip6963',
  EMAIL_CONNECTOR_ID: 'w3mEmail',
  EIP155: 'eip155',
  ADD_CHAIN_METHOD: 'wallet_addEthereumChain',
  EIP6963_ANNOUNCE_EVENT: 'eip6963:announceProvider',
  EIP6963_REQUEST_EVENT: 'eip6963:requestProvider',
  VERSION: '3.5.7'
};
