<div class="container">
  <h1 class="text-center">Terms of Service - Sunwave</h1>
  <p><strong>Whitepaper and all other information cited in this documentation ad published on official website:</strong>
    <a href="https://specialmetalx.com/">https://specialmetalx.com/</a></p>
  <p><strong>Anthis International SA</strong><br>
    6900 Lugano, Switzerland<br>
    info&#64;sunwave.com</p>

  <h2>Legal Notice</h2>
  <p>Please read this legal notice carefully. In case of doubt, contact lawyers, legal experts or other competent legal
    professionals. All information provided does not claim to be complete and should not be construed as part of a
    separate contractual agreement.</p>

  <h2>SMETX Token</h2>
  <p>The SMETX token is an asset token created on the Ethereum blockchain, so it cannot be purchased or used for
    speculative or investment purposes. The SMETX token will be issued by a technical process that uses the Ethereum
    Blockchain. This is an open-source IT protocol over which Anthis International SA has no rights or liability in
    terms of its development and operation. By purchasing the SMETX token, the Buyer acknowledges and understands
    therefore that Anthis International SA (incl. its bodies and employees) assumes no liability or responsibility for
    any loss or damage that would result from or relate to the incapacity to use the SMETX token, the Anthis
    International SA’s Platform, excepted in case of intentional misconduct or gross negligence. The SMETX token is
    based on the Ethereum Blockchain. Any malfunction, unplanned function or unexpected operation of the Ethereum
    Blockchain may cause the SMETX token to malfunction or operate in a way that is not expected. Anthis International
    SA assumes no liability or responsibility in this respect except in case of intentional misconduct or gross
    negligence directly attributable to Anthis International SA. Anthis International SA assumes no liability or
    responsibility whatsoever for any loss of the SMETX token or situations making it impossible to access the SMETX
    token, which may result from any actions or omissions of the Buyer, as well as in case of hacker attacks. Anthis
    International SA is not liable for any loss or damage arising from the use of the material and contents of this
    White Paper, including written material, links to third party sites, data, quotes, charts and buy/sell signals.</p>

  <h2>Knowledge Required</h2>
  <p>Any buyer purchasing the SMETX token shall understand and have significant experience of cryptocurrencies,
    blockchain systems and services, and understand the risks associated with the purchase of the SMETX token as well as
    the mechanisms related to the use and custody of cryptocurrencies. By purchasing the SMETX token the buyer
    acknowledges having carefully reviewed this White Paper so to understand the risks, costs and benefits associated
    with the purchase, storage and use of the SMETX token.</p>

  <!-- More sections can be added here -->

  <h2>No Investment Invitation</h2>
  <p>The SMETX token is not qualified as a security, therefore this White Paper shall not and cannot be considered as a
    prospectus or as an invitation to enter into an investment. This White Paper do not constitute or relate in any way,
    nor should they be considered or interpreted as an offering of securities in any jurisdiction. This White Paper do
    not include or contain any information or indication that might be considered or interpreted as a recommendation or
    that might be used to base any investment decision. This White Paper do not constitute an offer or an invitation to
    purchase shares, bonds, securities or rights belonging to Anthis International SA or any related or associated
    company (the “Anthis International SA’s Group”). This White Paper does not obligate you to enter into a contract or
    make a legally binding commitment to contribute. This White Paper does not constitute any form or part of an opinion
    that could be construed as advice or that could be used to sell or solicit an offer to purchase our token, nor
    should it be construed as part of an effect that could be used in the formation of a contract or an investment or
    purchase decision.</p>

  <h2>No Security</h2>
  <p>The sale of SMETX token is final: the SMETX token is non-refundable and not redeemable. The SMETX token are not
    convertible in shares or certificates of the Anthis International SA or Anthis International SA’s Group and do not
    grant any right to receive any such share or certificate. The SMETX token do not confer any direct or indirect right
    to Anthis International SA or Anthis International SA’s Group capital or income and, in particular, do not grant any
    right to dividends or interests or to any other share or participation to the Anthis International SA or Anthis
    International SA’s Group revenue or earnings. The SMETX token are not shares or participation certificates and do
    not give any right to participate to, or vote in, the general meeting of the Anthis International SA or the Anthis
    International SA ‘s Group or to influence in any way the respective corporate governance or the decisions of the
    corporate bodies of the Anthis International SA or the Anthis International SA ‘s Group. Anthis International SA
    considers that the SMETX token does not have the legal qualification of a security pursuant to Swiss Law. The
    offering of the SMETX token on an exchange platform is not done for speculative purposes and does not change the
    legal qualification of the token as an asset backed token.</p>

  <h2>No Offer – No Investment Advise – No Representations and Warranties</h2>
  <p>This White Paper shall not be construed as an offer, personal recommendation or solicitation to conclude a
    transaction and should not be treated as giving investment advice. Anthis International SA is not to be considered
    as an advisor in any legal, tax or financial matters. Any information in the White Paper is given for general
    information purpose only and Anthis International SA does not provide any representation and/or warranty as to the
    accuracy and completeness of the information included in this White Paper. Given the lack of qualification of the
    crypto-token in most countries, the buyer is strongly advised to carry out a legal and tax analysis concerning the
    purchase and ownership of the SMETX token according to his/her nationality and place of residence.</p>

  <h2>Tax Compliance</h2>
  <p>Buyers of the SMETX token are entirely responsible for determining any taxes applicable to their transactions. The
    Company is not responsible for determining the taxes applicable to transactions.</p>
</div>
