import { numberToHex } from '../../utils/encoding/toHex.js';
/**
 * Adds an EVM chain to the wallet.
 *
 * - Docs: https://viem.sh/docs/actions/wallet/addChain.html
 * - JSON-RPC Methods: [`eth_addEthereumChain`](https://eips.ethereum.org/EIPS/eip-3085)
 *
 * @param client - Client to use
 * @param parameters - {@link AddChainParameters}
 *
 * @example
 * import { createWalletClient, custom } from 'viem'
 * import { optimism } from 'viem/chains'
 * import { addChain } from 'viem/wallet'
 *
 * const client = createWalletClient({
 *   transport: custom(window.ethereum),
 * })
 * await addChain(client, { chain: optimism })
 */
export async function addChain(client, {
  chain
}) {
  const {
    id,
    name,
    nativeCurrency,
    rpcUrls,
    blockExplorers
  } = chain;
  await client.request({
    method: 'wallet_addEthereumChain',
    params: [{
      chainId: numberToHex(id),
      chainName: name,
      nativeCurrency,
      rpcUrls: rpcUrls.default.http,
      blockExplorerUrls: blockExplorers ? Object.values(blockExplorers).map(({
        url
      }) => url) : undefined
    }]
  });
}
