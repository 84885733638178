import { holesky } from "viem/chains";

export const environment = {
    production: false,
    API_BASE_URL:'https://devapi.sunwavecoin.com/api/v1',
    PROVIDER:'https://ethereum-holesky-rpc.publicnode.com',
    CONTRACT_ADDRESS:'0xF6f5f264d8E020C424c5D9aEE2F5014B4e7760Af',
    LOGO:'assets/images/logo.png',
    TITLE:'SUNWAVE-FRONTEND',
    NETWORK:'HOLESKEY Testnet',
    TYPE:'TESTNET',
    FROMTOKENNAME:'BNB',
    TOTOKENNAME:'SUNWAVE',
    TRANSLINK:'https://holesky.etherscan.io/tx',
    SUPPORTMAIL:'info@sunwave.co',
    SUPPORTED_CHAINS: [holesky],
    WALLET_CONNECT_PROJECT_ID: '66932ffba082b546f31afb2089524bb9',
    CHAIN_ID:17000,
    ENCRYPT_LOCAL_STORAGE: false,
    LOCAL_STORAGE_SECRET: 'D96Q2M84E3400063E8366912AQ45488H',
    APP_DESCRIPTION: 'SUNWAVE Public Sale',
    APP_URL: 'https://sunwave.com',
  };

