<section class="signup">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-8 mx-auto">
        <div class="signup-wrap">
          <h3>SignUp</h3>
          <p>Create an account to start holding <b>Sunwave</b></p>
          <form [formGroup]="signUpForm">
            <!-- Name -->
            <div class="form-group">
              <label for="">Name <span class="mandatory">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Your Name" formControlName="name">
              <ng-container *ngIf="signUpForm.get('name')?.touched">
                <div *ngIf="signUpForm.get('name')?.hasError('required')"
                  class="text-danger small">
                  This field is required.
                </div>
                <div *ngIf="signUpForm.get('name')?.hasError('pattern')"
                class="text-danger small">
                Name must start with a letter and can only contain alphabetic characters and spaces.
                </div>
                <div *ngIf="signUpForm.get('name')?.hasError('maxlength')"
                class="text-danger small">
                Name cannot exceed 20 characters.
                </div>
              </ng-container>
            </div>
            <!-- Email -->
            <div class="form-group">
              <label for="">Email <span class="mandatory">*</span></label>
              <input type="email" class="form-control" placeholder="Email" formControlName="email">
              <ng-container *ngIf="signUpForm.get('email')?.touched">
                <div *ngIf="signUpForm.get('email')?.hasError('required')"
                  class="text-danger small">
                  This field is required.
                </div>
                <div *ngIf="signUpForm.get('email')?.hasError('pattern')"
                  class="text-danger small">
                  Please enter valid email address.
                </div>
              </ng-container>
            </div>
            <div class="btn-signup">
              <button *ngIf="!isRegister" type="submit"
                [ngClass]="{'btn btn-primary': signUpForm.valid && !isRegister, 'btn btn-secondary cursor-not-allowed': !signUpForm.valid}"
                class="wallet" (click)="register()" [disabled]="!signUpForm.valid">
                 SignUp</button>
              <button *ngIf="isRegister"class="wallet" [disabled]="true"
              [ngClass]="{'btn btn-primary': signUpForm.valid && !isRegister, 'btn btn-secondary cursor-not-allowed': !signUpForm.valid}"
              >Signing Up...</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</section>
<!-- Modal -->
<!-- <div *ngIf="showKYCModal" class="modal fade show" style="display: block;" id="kycVerificationModal" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">KYC Verification</h1>
      </div>
      <div class="modal-body signup-wraps  mt-0">
        <div class="row">
          <div class="col-lg-12 mt-3">
            <h3 class="text-center px-4 pb-3" *ngIf="user.kyc_verified === kycStatus.NOT_INITIATED">Your KYC process has not been initiated
              yet. Please start the process to verify your identity.</h3>
            <h3 class="text-center px-4 pb-3" *ngIf="user.kyc_verified === kycStatus.VERIFIED">Your KYC process has been successfully
              verified. Thank you!.</h3>
            <h3 class="text-center px-4 pb-3" *ngIf="user.kyc_verified === kycStatus.PROCESSING">Your KYC process is currently pending. We
              are reviewing your information and will update you soon.</h3>
            <h3 *ngIf="user.kyc_verified === kycStatus.REJECTED" class="text-center px-4 pb-3">Oops! It seems there was an issue with
              your KYC document. sumsub team has
              reviewed it and unfortunately, it has been rejected. Following the
              guidelines provided to ensure successful verification. If you have any questions or need assistance, feel
              free to contact our support team. We apologize for any inconvenience caused.</h3>
            <h3 class="text-center px-4 pb-3">Refresh the page to see the current status.</h3>
            <div class="d-flex justify-content-center" *ngIf="user.kyc_verified === kycStatus.NOT_INITIATED || user.kyc_verified === kycStatus.REJECTED">
              <button class="btn btn-primary kyc-btn" (click)="reUploadKyc()">Initiate KYC</button>
            </div>
            <p class="pt-4" *ngIf="user.kyc_verified === kycStatus.NOT_INITIATED || user.kyc_verified === kycStatus.REJECTED"><span class="fw-bold">Note: </span>If you are not redirected automatically, please enable browser pop-ups to initiate KYC.
              If this issue persists, ensure pop-ups are allowed in your browser settings.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
