import { holesky } from "viem/chains";

export const environment = {
    production: false,
    API_BASE_URL:'https://devapi.sunwavecoin.com/api/v1',
    PROVIDER:'https://ethereum-holesky.blockpi.network/v1/rpc/public',
    CONTRACT_ADDRESS:'0x657f2ED1E364e803806a56D87e0245ACcC32eAd2',
    LOGO:'assets/images/logo.png',
    TITLE:'SUNWAVE-FRONTEND',
    NETWORK:'HOLESKEY Testnet',
    TYPE:'TESTNET',
    FROMTOKENNAME:'BNB',
    TOTOKENNAME:'SUNWAVE',
    TRANSLINK:'https://holesky.etherscan.io/tx',
    SUPPORTMAIL:'info@sunwave.co',
    SUPPORTED_CHAINS: [holesky],
    WALLET_CONNECT_PROJECT_ID: '66932ffba082b546f31afb2089524bb9',
    CHAIN_ID:17000,
    ENCRYPT_LOCAL_STORAGE: false,
    LOCAL_STORAGE_SECRET: 'D96Q2M84E3400063E8366912AQ45488H',
    APP_DESCRIPTION: 'SUNWAVE Public Sale',
    APP_URL: 'https://sunwave.com',
    USDT_CONTRACT_ADDRESS: '0x631487008A1C3D5952BA5198a93660cD7428F3df'
  };

