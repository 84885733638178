import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchNetwork, watchNetwork, getNetwork, watchAccount } from '@wagmi/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ContractService } from 'src/app/services/contract.service';
import { environment } from 'src/environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Modal } from 'bootstrap';
import { VestingButton } from 'src/app/interfaces/common..interface';
import { ApiResponse, UserRegisterStatus, TokenInfo, VestingUserInfo, UserInfo } from 'src/app/services/types/api-schema.types';
import * as roadMapData from "./roadmap-data";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public usdtTokenObject: TokenInfo = {
    tokenAddress: '',
    name: '',
    symbol: '',
    decimal: 0
  };
  public sunwaveTokenObject: TokenInfo = {
    tokenAddress: '',
    name: '',
    symbol: '',
    decimal: 0
  };
  public vestingUserInfo: VestingUserInfo = {
    availableToClaim: 0,
    claimedBalance: 0,
    nextClaimDatetime: 0,
    monthsCompleted: 0,
    totalAmount: 0,
    currentIntervalAmount: 0,
    isFounder: false
  };
  public rewardTokenBalanceInContract = 0;
  public vestingAddress: string = '';
  public lockupPeriodInDays = 0;
  public isClaimLoading = false;
  public vestingButton = VestingButton;
  public buttonState: VestingButton = VestingButton.NoContributionYet;
  public vestingMonth = 0;
  TOTOKENNAME: any;
  saleEnd: string = '';
  saleStart: string = '';
  tokenRate: any;
  lockupPeriod: number;
  transformedLockupPeriod: string = '';
  contractAddress: string = '';
  isLoading = false;
  tokenProcess = false;
  calculatorFromText: any;
  calculatorToText: any;
  calculatorFrom: any;
  from: any;
  to: any;
  calculatorTo: any;
  getTransError = false;
  public usdtTokenBalance = 0;
  public checkSaleDate = true;
  public isFinalized = false;
  public tokenAddress: string = '';
  public checkSaleStartDate = false;
  public usdtTokenAddress: string = '';
  public userInfo: UserInfo = {
    usdtContributed: 0,
    sunwaveRecieved: 0
  };
  isRefValidAddress = true;
  public currentWalletAddress: string;
  public closingTime: any;
  schedules = [
    { label: 'Days', value: 0 },
    { label: 'Hours', value: 0 },
    { label: 'Minutes', value: 0 },
    { label: 'Seconds', value: 0 },
  ];
  remainingDays = 0;
  remainingHours = 0;
  remainingMinutes = 0;
  remainingSeconds = 0;
  public walletAddress = '';
  isWrongNetwork = false;
  rewardTokenAddress: any
  bgColor: string = '';
  startingTime = 0;
  currentTime: Date;
  isUserRegistered = false;
  fromInputValue: string = '';
  toInputValue: string = '';
  disclaimerModal: Modal;
  agreedToDisclaimer = false;
  roadMapData = roadMapData.data;
  supportedNetwork: boolean;
  protected isFounder: boolean = false;
  vestingIntervalInSeconds: number = 0;
  constructor(
    private contractService: ContractService,
    private toastr: ToastrService,
    private router: ActivatedRoute,
    private routerNavigator: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private copyToClipboardService: ClipboardService,
    private localStorageService: LocalStorageService
  ) {
  }
  /**
   * on init
   */
  ngOnInit(): void {
    this.fetchWalletAddress();
    this.spinner.show();
    this.conn();
    this.TOTOKENNAME = environment.TOTOKENNAME;
    this.watchNetwork();
  }

  fetchWalletAddress() {
    this.authService.walletAddress$.subscribe(address => {
      this.conn();
      this.currentWalletAddress = address;
      this.currentWalletAddress && this.checkRegisteredUser(this.currentWalletAddress);
    });
  }

  /**
   * Watchs network
   */
  protected watchNetwork() {
    watchNetwork((network) => {
      if (network.chain?.unsupported) {
        this.isWrongNetwork = true;
        this.supportedNetwork = network.chain?.unsupported;
        this.toastr.error('Wrong Network Connected');
        setTimeout(() => {
          this.switchNetwork();
        }, 2000);
      } else {
        this.isWrongNetwork = false;
      }
    });
  }

  /**
   * Switchs network
   */
  public async switchNetwork() {
    this.isWrongNetwork = true;
    switchNetwork({
      chainId: environment.CHAIN_ID
    }).then(() => {
    this.isWrongNetwork = false;
    });
  }

  /**
   * Checks registered user
   * @param walletAddress
   */
  private checkRegisteredUser(walletAddress: string) {
    this.authService.checkUserRegistrationStatus(walletAddress).subscribe({
      next: (response: ApiResponse<UserRegisterStatus>) => {
        this.isUserRegistered = !response.status;
        this.conn();
      },
      error: (error) =>  {
        const errorMessage = error?.error?.message || "Something went wrong try after";
        this.toastr.error(errorMessage);
      }
    })
  }

  /**
  * Conn of index component
  */
  public conn = async () => {
    this.spinner.show();
    const rewardTokenAddress = await this.contractService.getTokenAddress();
    this.sunwaveTokenObject = await this.contractService.getTokenInfo(rewardTokenAddress);
    this.usdtTokenAddress = await this.contractService.getUSDTTokenAddress();
    this.usdtTokenObject = await this.contractService.getTokenInfo(this.usdtTokenAddress);
    const rewardTokenBalanceInDecimal = await this.contractService.getRewardTokenBalanceForSale();
    this.rewardTokenBalanceInContract = this.limitDecimals(rewardTokenBalanceInDecimal / 10 ** this.sunwaveTokenObject.decimal, 4);
    this.vestingMonth = await this.contractService.getVestingMonths();
    await this.vestingInfos();
    if (this.currentWalletAddress) {
      this.getBalance();
      this.getUSDTBalance();
      this.isFounder = await this.contractService.isFounder(this.currentWalletAddress);
      if (this.isFounder) {
        this.vestingMonth = 24;
        this.vestingIntervalInSeconds = await this.contractService.getIntervalTime();
        this.transformedLockupPeriod = this.transformLockInPeriod(6 * this.vestingIntervalInSeconds);        
      } else {
        this.transformedLockupPeriod = this.transformLockInPeriod(this.lockupPeriod);
      }
    } else {
      this.isFounder = false;
      this.usdtTokenBalance = 0;
      this.userInfo = {
        usdtContributed: 0,
        sunwaveRecieved: 0
      };
    }
    this.contractAddress = this.contractService.getContractAddress();
    this.isFinalized = await this.contractService.isFinalized();
    this.closingTime = await this.contractService.getClosingTime();
    this.startingTime = await this.contractService.getStartingTime();
    this.startTime()
    const closingDate = new Date(new Date((parseInt(this.closingTime as any)) * 1000).toISOString());
    const startingDate = new Date(new Date((parseInt(this.startingTime as any)) * 1000).toISOString());
    this.checkSaleDate = moment(closingDate).isAfter(Date());
    this.checkSaleStartDate = moment(startingDate).isAfter(Date());
    this.saleEnd = closingDate.getDate() + "/" + (closingDate.getMonth() + 1) + "/" + closingDate.getFullYear() + " " + closingDate.getHours() + ":" + closingDate.getMinutes() + ":" + closingDate.getSeconds();
    this.saleStart = startingDate.getDate() + "/" + (startingDate.getMonth() + 1) + "/" + startingDate.getFullYear() + " " + startingDate.getHours() + ":" + startingDate.getMinutes() + ":" + startingDate.getSeconds();
    this.lockupPeriod = await this.contractService.getInitialLockupPeriod();
    this.tokenRate = this.convert(parseInt(await this.contractService.getRate())) / 10000;
    this.calculatorFromText = 1;
    this.calculatorToText = this.tokenRate;
    this.spinner.hide();
  }

  /**
   * Vesting infos
   */
  private async vestingInfos() {
    this.vestingAddress = await this.contractService.getVestingAddress();
    const lockupPeriod = await this.contractService.getInitialLockupPeriod();
    this.lockupPeriodInDays = lockupPeriod;
    if (this.currentWalletAddress) {
      const userVestingDatas = await this.contractService.getUserVestingDatas(this.currentWalletAddress, 1);
      const nextClaimDatetime = userVestingDatas.nextClaimDate * 1000;
      const availableToClaim = this.limitDecimals(userVestingDatas.availableToClaim / 10 ** this.sunwaveTokenObject.decimal, 4);
      const claimedBalance = this.limitDecimals(userVestingDatas.claimedTokens[1] / 10 ** this.sunwaveTokenObject.decimal, 4);
      const monthsCompleted = userVestingDatas.claimedTokens[0];
      const totalAmount = this.limitDecimals(userVestingDatas.totalAmount / 10 ** this.sunwaveTokenObject.decimal, 4);
      const currentIntervalAmount = this.limitDecimals(userVestingDatas.vestingCurrentIntervalDatas[1] / 10 ** this.sunwaveTokenObject.decimal, 4);      
      const isFounder = userVestingDatas.isFounder;
      const userVestingStartTime = userVestingDatas.userVestingStartTime;
      // this.vestingIntervalInSeconds = await this.contractService.getIntervalTime();
      // console.log('vestingIntervalInSeconds', this.vestingIntervalInSeconds);
      
      this.vestingUserInfo = {
        availableToClaim, claimedBalance, nextClaimDatetime, monthsCompleted, totalAmount, currentIntervalAmount, isFounder, 
      };
      // console.log('userVestingStartTime', userVestingStartTime, this.vestingIntervalInSeconds, this.currentTimeInSeconds());
      // get vesting startTime of the user * lockIn interval  = lockInPeriodEndDataTime
      const lockInPeriod: any = 6 * this.vestingIntervalInSeconds;
      const userVestingLockInPeriodEndTime = parseInt(userVestingStartTime) + parseInt(lockInPeriod);

    //  console.log('userVestingLockInPeriodEndTime', userVestingLockInPeriodEndTime);
    //   console.log('currentIntervalAmount', currentIntervalAmount, nextClaimDatetime, monthsCompleted, this.vestingMonth);
      
      // Check if the lock-in period has ended
        if (userVestingLockInPeriodEndTime > this.currentTimeInSeconds()) {          
          this.buttonState = this.vestingButton.LockinPeriod;
        } else if (monthsCompleted == this.vestingMonth) {
          this.buttonState = this.vestingButton.VestingDurationEnded;
        } else if (currentIntervalAmount > 0) {
          this.buttonState = this.vestingButton.ClaimYourTokens;
        } else if (currentIntervalAmount === 0 && nextClaimDatetime !== 0) {
          this.buttonState = this.vestingButton.WaitForNextClaimDate;
        } else {
          this.buttonState = nextClaimDatetime === 0
              ? this.vestingButton.NoContributionYet
              : this.vestingButton.WaitForNextClaimDate;
        }
    } else {
      this.vestingUserInfo = {
        availableToClaim: 0,
        claimedBalance: 0,
        nextClaimDatetime: 0,
        monthsCompleted: 0,
        totalAmount: 0,
        currentIntervalAmount: 0,
        isFounder: false
      };
    }
  }

  /**
   * Transforms a time period given in seconds to a more readable string format.
   *
   * @param {number} timePeriodInSecs - The time period in seconds.
   * @returns {string} - The time period in a more readable format (months, days, hours, minutes, or seconds).
   */
    public transformLockInPeriod(timePeriodInSecs: number): string {
      const units = [
        { label: 'month', seconds: 2628000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ];
      for (const { label, seconds } of units) {
        if (timePeriodInSecs >= seconds) {
          const value = this.roundOff(timePeriodInSecs / seconds);
          return `${value} ${label}${value > 1 ? 's' : ''}`;
        }
      }
      return '0 seconds';
    }
    // Rounds off a value to one decimal place.
    private roundOff(value: number): number {
      return parseFloat(value.toFixed(1));
    }

  /**
   * Gets balance
   */
  async getBalance() {
    const userInfo = await this.contractService.getUserBalances(this.currentWalletAddress);
    const usdtContributed = this.limitDecimals(userInfo.usdtContributed / 10 ** this.usdtTokenObject.decimal, 4);
    const sunwaveRecieved = this.limitDecimals(userInfo.sunwaveRecieved / 10 ** this.sunwaveTokenObject.decimal, 4);
    this.userInfo = { usdtContributed, sunwaveRecieved };
  }

  /**
   * Gets usdtbalance
   */
  async getUSDTBalance() {
    const usdtTokenBalances = await this.contractService.getBalanceOf(this.currentWalletAddress, this.usdtTokenAddress)
    this.usdtTokenBalance = this.limitDecimals(usdtTokenBalances / 10 ** this.usdtTokenObject.decimal, 4);
  }

  /**
   * Froms input
   */
  public fromInput() {
    this.from = (<HTMLInputElement>document.getElementById("from")).value;
    // Calculate
    if (this.from == "") {
      this.from = '0';
    }

    this.calculatorFrom = this.limitDecimals(this.from * this.tokenRate, 4);
    (<HTMLInputElement>document.getElementById("to")).value = this.convert(this.calculatorFrom);
    this.calculatorToText = this.convert(this.calculatorFrom);
    this.calculatorFromText = this.convert(this.from);
  }

  private limitDecimals(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    if (Math.round(value * factor) / factor !== value) {
      return parseFloat(value.toFixed(decimals));
    }
    return value;
  }

  /**
   * USDT input key press
   * @param event
   */
  inputKeyPress(event: KeyboardEvent, currentValue: string): void {
    const char = String.fromCharCode(event.which);

    // Allow control keys such as backspace
    if (event.ctrlKey || event.metaKey || char === '\b') {
      return;
    }

    // Only allow digits and one decimal point
    const allowedChars = /[0-9.]/;

    if (!allowedChars.test(char)) {
      event.preventDefault();
      return;
    }

    // Prevent more than one decimal point
    if (char === '.' && currentValue.includes('.')) {
      event.preventDefault();
      return;
    }

    // Ensure no more than four decimal places
    const [integerPart, decimalPart] = currentValue.split('.');
    if (decimalPart && decimalPart.length >= 4) {
      event.preventDefault();
    }
  }

  /**
   * To input
   */
  public toInput() {
    this.to = (<HTMLInputElement>document.getElementById("to")).value;
    if (this.to == "") {
      this.from = '0';
    }
    // Calculate
    this.calculatorTo = this.limitDecimals(this.to / this.tokenRate, 4);
    (<HTMLInputElement>document.getElementById("from")).value = this.convert(this.calculatorTo);
    this.calculatorToText = this.convert(this.to);
    this.calculatorFromText = this.convert(this.calculatorTo);
  }

  /**
   * Claims vested tokens
   */
  async claimVestedTokens() {
    try {
      this.isClaimLoading = true;
      const claimTokenAbi = await this.contractService.claimVestedTokens(1);
      const claimToken = await this.contractService.sendTransaction(claimTokenAbi, this.vestingAddress);
      if (claimToken) {
        this.vestingInfos();
        this.isClaimLoading = false;
        this.toastr.success("Claimed Successfully");
      }

    } catch (error: any) {
      this.isClaimLoading = false;
      if (error?.data?.cause?.cause?.code === 4001) {
        this.toastr.error(error?.data?.cause?.cause?.message);
      } else {
        this.toastr.error(error);
      }
    }
  }

  /**
   * Buy now of home component
   */
  buyNow = async () => {
    this.watchNetwork();
    const usdtContribution: any = (<HTMLInputElement>document.getElementById("from")).value;
    this.getTransError = false;
    if (!this.currentWalletAddress) {
      this.toastr.error("Connect to the wallet");
      this.isLoading = false;
      return false;
    }
    else if (this.currentWalletAddress) {
      this.isLoading = true;
      if (usdtContribution == "") {
        this.toastr.error("Invalid Inputs");
        this.isLoading = false;
        return false;
      }
      try {
        const getAllowance = await this.contractService.getAllowance(this.currentWalletAddress, this.usdtTokenObject.tokenAddress);
        const usdtContributionInDecimal = usdtContribution * 10 ** this.usdtTokenObject.decimal;
        if (usdtContributionInDecimal > getAllowance.usdtBalance) {
          this.toastr.error('Insufficient USDT Balance');
          this.isLoading = false;
          return;
        }
        if (getAllowance.approvedAmount < usdtContributionInDecimal) {
          let approveAbi = await this.contractService.approveAbi(environment.CONTRACT_ADDRESS, usdtContribution, this.usdtTokenObject.tokenAddress);
          await this.contractService.sendTransaction(approveAbi, this.usdtTokenObject.tokenAddress)
        }
        const buyAbi = await this.contractService.buyToken(this.currentWalletAddress, usdtContributionInDecimal);
        await this.contractService.sendTransaction(buyAbi, environment.CONTRACT_ADDRESS).then((results: any) => {
          this.conn();
          this.vestingInfos();
          (<HTMLInputElement>document.getElementById("from")).value = '';
          (<HTMLInputElement>document.getElementById("to")).value = '';
          this.isLoading = false;
          this.toastr.success('Transaction Successful');
        });
        return;
      }
      catch (err: any) {
        this.isLoading = false;
        this.toastr.error(this.getErrorMessage(err));
        return false;
      }
    }
    else {
      this.toastr.error("Connect to the wallet");
      this.isLoading = false;
      return false;
    }
  }

  /**
   * Gets the error message from the error object
   * @param err The error object
   * @returns The error message
   */
  private getErrorMessage(error: any): string {
    // Try to get the error message from the error object
    // If the error object is nested, try to get the error message from the inner error object
    // If the error message is not found, return a default error message
    return error?.data?.cause?.cause?.message
      ?? error?.data?.shortMessage
      ?? error?.message
      ?? 'An unexpected error occurred';
  }

  /**
   * Validates number
   * @param value
   * @returns true if number
   */
  validateNumber(value: string): boolean {
    return /^[0-9]*\.?[0-9]{0,2}$/.test(value);
  }

  // Convert Expontential value
  /**
   * Converts home component
   * @param n
   * @returns
   */
  convert(n: any) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }

  /**
   * Closes View Transaction alert
   */
  public close() {
    this.tokenProcess = false;
  }

  /**
   * Remaining time of home component
   */
  remainingTime = (time: any) => {
    const closingDate: Date = new Date(time * 1000);
    const interval = setInterval(() => {
      const currentTime: Date = new Date();
      const timeDifference = closingDate.getTime() - currentTime.getTime(); // Recalculate time difference every interval
      if (timeDifference <= 0) {
        clearInterval(interval);
        this.schedules.forEach((item) => (item.value = 0)); // Set all values to 0 when time is up
        return;
      }
      this.schedules[0].value = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.schedules[1].value = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.schedules[2].value = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      this.schedules[3].value = Math.floor((timeDifference % (1000 * 60)) / 1000);
    }, 1000);
  }

  /**
   * startTime
   */
  async startTime() {
    const startTimeDate: Date = new Date(this.startingTime * 1000);
    this.currentTime = new Date();
    this.currentTime > startTimeDate ? this.remainingTime(this.closingTime) : this.remainingTime(this.startingTime);
  }

  currentTimeInSeconds() {
    return Math.floor(Date.now() / 1000);
  }

  /**
   * Copys to clipboard
   * @param value
   */
  copyToClipboard(value: any) {
    this.copyToClipboardService.copy(value);
    this.toastr.success('Copied successfully');
  }

  /**
   * Determines whether the "Buy Now" button should be disabled
   * @returns {boolean}
   */
  public disableBuyNOW(): boolean {
    return !this.checkSaleDate || Number(this.fromInputValue) <= 0 || this.checkSaleStartDate || this.isLoading || !this.isRefValidAddress || this.currentWalletAddress === this.walletAddress || (this.vestingUserInfo.monthsCompleted === this.vestingMonth)
  }

  /**
   *
   * Updates the wallet address in local storage.
   */
  updateWalletAddress(connection: any) {
    const walletAddress = connection?.address;
    walletAddress ? this.localStorageService.storeData('wallet-address', walletAddress) : this.localStorageService.clearLocalStorage('wallet-address');
  }

   // Method to check if the date should be shown or not
   shouldShowNextClaimDate(): boolean {
    return (
      this.buttonState !== 'VESTING DURATION ENDED' &&
      this.vestingUserInfo.nextClaimDatetime !== 0 &&
      this.buttonState !== 'LOCKIN PERIOD' &&
      (this.buttonState === 'WAIT FOR NEXT CLAIM DATE' || this.buttonState === 'CLAIM YOUR TOKENS')
    );
  }

  // Method to return the default date format
  getDefaultDate(): boolean {
    return (
      this.vestingUserInfo.nextClaimDatetime === 0 ||
      (this.buttonState === 'VESTING DURATION ENDED' ||
      this.buttonState === 'LOCKIN PERIOD' ||
      this.buttonState !== 'CLAIM YOUR TOKENS') && this.buttonState !== 'WAIT FOR NEXT CLAIM DATE'
    );
  }


}
