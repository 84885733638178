import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from './modules/mat-module';
import { TermsOfServiceComponent } from './component/terms-of-service/terms-of-service.component';
import { HeaderComponent } from './component/header/header.component';
import { ScheduleCardComponent } from './component/schedule-card/schedule-card.component';
import { RoadMapCardComponent } from './component/road-map-card/road-map-card.component';



@NgModule({
  declarations: [
    TermsOfServiceComponent,
    HeaderComponent,
    ScheduleCardComponent,
    RoadMapCardComponent
  ],
  imports: [
    CommonModule,
    MatModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MatModule, TermsOfServiceComponent, HeaderComponent, ScheduleCardComponent,RoadMapCardComponent]
})
export class SharedModule { } 