export const data = [
    {
        month:"June - 2021",
        value:"Conceptualization.",
        class:"roadmap-right"
    },
    {
        month:"August - 2021",
        value:"Token development.",
        class:"roadmap-left"
    },
    {
        month:"September - 2021",
        value:" Mainnet launch.",
        class:"roadmap-right"
    },
    {
        month:"November - 2021",
        value:"Public sale.",
        class:"roadmap-left"
    },
    {
        month:"October - 2021",
        value:" Marketing campaign starts.",
        class:"roadmap-right"
    }, 
    {
        month:"December - 2021",
        value:"Market tracker listings.",
        class:"roadmap-left"
    },
    {
        month:"December - 2021",
        value:" Pancakeswap listing.",
        class:"roadmap-right"
    },
    {
        month:"December - 2021",
        value:"sample DEX launch.",
        class:"roadmap-left"
    },
    {
        month:"December - 2021",
        value:"sample Airdrop.",
        class:"roadmap-right"
    },
    {
        month:"February - 2022",
        value:" Influencer marketing campaign.",
        class:"roadmap-left"
    },
    {
        month:"February - 2022",
        value:"NFT Marketplace development.",
        class:"roadmap-right"
    },
    {
        month:"March - 2022",
        value:"NFT Marketplace launch.",
        class:"roadmap-left"
    },
]